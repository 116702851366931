const devCloudinaryConfig: any = {
  cloud_name: 'ukrick-2016'
};

const prodCloudinaryConfig: any = {
  cloud_name: 'energodosvid-com-ua'
};

export const ENV_CLOUDINARY_CONFIG: any = process.env.NODE_ENV !== 'production' ?
  devCloudinaryConfig :
  prodCloudinaryConfig;
