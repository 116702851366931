import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { Injectable } from '@angular/core';
import { of } from 'rxjs';

import { LandscapeImageResolver } from './resolvers/landscape-image-resolver.service';
import { PortraitImageResolver } from './resolvers/portrait-image-resolver.service';

@Injectable()
export class DataResolver implements Resolve<any> {
  resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
    return of({ res: 'I am data'});
  }
}

/**
 * An array of services to resolve routes with data.
 */
export const APP_RESOLVER_PROVIDERS = [
  DataResolver,
  LandscapeImageResolver,
  PortraitImageResolver
];
