import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ScriptService {

  private scripts: any = {};

  private appScripts: any[] = [
    { name: 'cjs', src: 'https://cdn.jsdelivr.net/npm/chart.js@2.8.0' }
  ];

  constructor() {
    this.appScripts.forEach((script: any) => {
      this.scripts[script.name] = {
        loaded: false,
        src: script.src
      };
    });
  }

  load(...scripts: string[]) {
    const promises: any[] = [];
    scripts.forEach((script) => promises.push(this.loadScript(script)));
    return Promise.all(promises);
  }

  private loadScript(name: string) {
    return new Promise((resolve, reject) => {
      // resolve if script already loaded
      if (this.scripts[name].loaded) {
        resolve({ script: name, loaded: true, status: 'Already Loaded' });
      } else {
        // load script
        const tag = document.createElement('script');
        tag.type = 'text/javascript';
        tag.src = this.scripts[name].src;
        tag.crossOrigin = 'anonymous';
        tag.onload = () => {
          this.scripts[name].loaded = true;
          resolve({ script: name, loaded: true, status: 'Loaded' });
        };
        tag.onerror = (e: any) => resolve({ script: name, loaded: false, status: e.message });
        document.getElementsByTagName('head')[0].appendChild(tag);
      }
    });
  }
}
