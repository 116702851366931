<section fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <div fxLayout="column" fxLayoutAlign="center center">
        <h2>Послуги</h2>
        <h3>Всі види електромонтажних робіт, пусконаладка, обслуговування, документальний супровід.</h3>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex>
        <mat-list>
          <mat-list-item>
            <div mat-line>
              <img src="assets/images/check-mark.png" alt="*">
              Проектування і будівництво сонячних електростанцій «під ключ» з повним пакетом документів для оформлення
              «зеленого тарифу» [<a routerLink="/calculator">розрахунок</a>].
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              <img src="assets/images/check-mark.png" alt="*">
              Монтаж, демонтаж, реконструкція, налагодження і обслуговування електроустановок споживачів (ТП- 10/0,4 кВ).
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              <img src="assets/images/check-mark.png" alt="*">
              Будівництво повітряних (ПЛ), кабельних (КЛ) ліній.
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              <img src="assets/images/check-mark.png" alt="*">
              Улаштування кінцевих, зєднувальних муфт КЛ.
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              <img src="assets/images/check-mark.png" alt="*">
              Монтаж заземлюючого пристрою.
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              <img src="assets/images/check-mark.png" alt="*">
              Всі види внутрішніх електромонтажних робіт.
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              <img src="assets/images/check-mark.png" alt="*">
              Виготовлення проектної документації та лабораторних вимірів.
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              <img src="assets/images/check-mark.png" alt="*">
              Супровід видачі дозвільної документації, здача в експлуатацію.
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              <img src="assets/images/check-mark.png" alt="*">
              Монтаж охоронної та пожежної сигналізації.
            </div>
          </mat-list-item>
          <mat-list-item>
            <div mat-line>
              <img src="assets/images/check-mark.png" alt="*">
              Послуги наступною спецтехнікою:
            </div>
          </mat-list-item>
          <mat-list-item class="nested">
            <div mat-line>
              <img src="assets/images/circle.png" alt="*">
              автовишка АП-17 на базі автомобіля ЗІЛ;
            </div>
          </mat-list-item>
          <mat-list-item class="nested">
            <div mat-line>
              <img src="assets/images/circle.png" alt="*">
              ямобур (БКМ) на базі автомобіля ГАЗ-66.
            </div>
          </mat-list-item>
        </mat-list>
        <div fxLayout="column" fxLayoutAlign="center start">
          <p>Компанія виконує роботи дотримуючись норм і стандартів електромонтажних робіт.</p>
          <p>Роботи виконуємо якісно та вчасно в домовлені з клієнтом строки.</p>
          <p>Перед початком робіт представник компанії виїде на об’єкт, щоб провести необхідні заміри та запише
            необхідні дані. Попередня оцінка вартості електромонтажних робіт визначається з урахуванням обсягу робіт,
            особливостей місцевості, призначення та функціональних вимог, використаного устаткування та матеріалів.
            Остаточна вартість об’єкта визначається за підсумками наданих послуг.
          </p>
          <p>Після погодження кінцевого проекту, наші досвідченні монтажники приступають до виконання електромонтажних
            робіт.
          </p>
        </div>
        <div fxLayout="row" fxLayoutAlign="start center" fxHide.lt-md>
          <cl-image public-id="electrical-engineer.jpg"
                    crop="scale"
                    dpr="auto"
                    height="140">
            <cl-transformation quality="90">
            </cl-transformation>
          </cl-image>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <app-footer fxLayout="row" class="mat-toolbar-multiple-rows"></app-footer>
</section>
