import { Component, Inject, OnDestroy, OnInit } from '@angular/core';

import { MAT_DIALOG_DATA } from '@angular/material/dialog';

import { ENV_CLOUDINARY_CONFIG } from '../../cloudinary.config';

import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';

import { Gallery, GalleryConfig, GalleryRef, ThumbnailsPosition } from 'ng-gallery';

import { Observable } from 'rxjs';

import { map } from 'rxjs/operators';

@Component({
  selector: 'app-widget-dialog',
  templateUrl: './widget-dialog.component.html',
  styleUrls: ['./widget-dialog.component.scss']
})
export class WidgetDialogComponent implements OnDestroy, OnInit {

  galleryConfig$: Observable<GalleryConfig>;

  constructor(@Inject(MAT_DIALOG_DATA) public data: any,
              private breakpointObserver: BreakpointObserver,
              private gallery: Gallery) {

    this.galleryConfig$ = breakpointObserver.observe([
      Breakpoints.HandsetPortrait
    ]).pipe(
      map((res) => {
        if (res.matches) {
          return {
            thumbPosition: ThumbnailsPosition.Top,
            thumbWidth: 90,
            thumbHeight: 90
          };
        }
        return {
          thumbPosition: ThumbnailsPosition.Left,
          thumbWidth: 120,
          thumbHeight: 120
        };
      })
    );
  }

  ngOnInit(): void {
    const galleryRef: GalleryRef = this.gallery.ref('appGallery');
    for (const i of this.data.publicIds) {
      galleryRef.addImage({
        src: `https://res.cloudinary.com/${ENV_CLOUDINARY_CONFIG.cloud_name}/image/upload/q_90/c_scale,dpr_auto,h_640/${i}`,
        thumb: `https://res.cloudinary.com/${ENV_CLOUDINARY_CONFIG.cloud_name}/image/upload/q_90/c_scale,dpr_auto,h_90/${i}`
      });
    }
    galleryRef.set(this.data.index);
  }

  ngOnDestroy(): void {
  }
}
