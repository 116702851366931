<mat-toolbar>
  <mat-toolbar-row>
    <img alt="" [src]="icon">
    <a routerLink="">
      <img [alt]="text" [src]="logo" fxHide.xs>
    </a>
    <span fxFlex></span>
    <button
      mat-icon-button
      [matMenuTriggerFor]="menu"
      *ngIf="mediaQueryList.matches"
      color="primary">
      <mat-icon>menu</mat-icon>
    </button>
    <mat-menu #menu="matMenu">
      <a mat-menu-item
         routerLink="company"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}">
        Компанія
      </a>
      <a mat-menu-item
         routerLink="details"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}">
        Послуги
      </a>
      <a mat-menu-item
         routerLink="prices"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}">
        Розцінки
      </a>
      <a mat-menu-item
         routerLink="equipment"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}">
        Наша техніка
      </a>
      <a mat-menu-item
         routerLink="services"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}">
        Наші роботи
      </a>
      <a mat-menu-item
         routerLink="contact-us"
         routerLinkActive="active"
         [routerLinkActiveOptions]="{exact: true}">
        Контакти
      </a>
    </mat-menu>
    <a mat-button
       routerLink="company"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}"
       *ngIf="!mediaQueryList.matches"
       color="primary">
      Компанія
    </a>
    <a mat-button
       routerLink="details"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}"
       *ngIf="!mediaQueryList.matches"
       color="primary">
      Послуги
    </a>
    <a mat-button
       routerLink="prices"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}"
       *ngIf="!mediaQueryList.matches"
       color="primary">
      Розцінки
    </a>
    <a mat-button
       routerLink="equipment"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}"
       *ngIf="!mediaQueryList.matches"
       color="primary">
      Наша техніка
    </a>
    <a mat-button
       routerLink="services"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}"
       *ngIf="!mediaQueryList.matches"
       color="primary">
      Наші роботи
    </a>
    <a mat-button
       routerLink="contact-us"
       routerLinkActive="active"
       [routerLinkActiveOptions]="{exact: true}"
       *ngIf="!mediaQueryList.matches"
       color="primary">
      Контакти
    </a>
  </mat-toolbar-row>
</mat-toolbar>
<main [@routeAnimation]="outlet.isActivated ? outlet.activatedRoute : null">
  <router-outlet #outlet="outlet"></router-outlet>
</main>
