<section [style.backgroundImage]="mediaQueryList.matches ?
      this.activatedRoute.snapshot.data.portraitImage :
      this.activatedRoute.snapshot.data.landscapeImage"
         fxLayout="column"
         fxLayoutAlign="space-between stretch">
  <div fxLayout="row" fxFlex="60%" fxFlex.lt-md="50%"></div>
  <div fxLayout="row" fxFlex="30%" fxFlex.lt-md="40%">
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column"
         fxLayoutAlign="space-evenly center"
         fxFlex="30%"
         fxFlex.lt-xl="40%"
         fxFlex.lt-lg="50%"
         fxFlex.lt-md="80%"
         class="welcome">
      <h1>електромонтажні роботи</h1>
      <h2>документальний супровід</h2>
      <a mat-button
         routerLink="details"
         color="primary">
        дізнатися більше
      </a>
    </div>
    <div fxLayout="column"
         fxFlex="60%"
         fxFlex.lt-xl="50%"
         fxFlex.lt-lg="40%"
         fxFlex.lt-md="10%">
    </div>
  </div>
  <div fxLayout="row" fxFlex="10%"></div>
</section>
