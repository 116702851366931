/**
 * Angular 2 decorators and services
 */
import { ChangeDetectorRef, Component, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';

import { MediaMatcher } from '@angular/cdk/layout';

import { APP_ANIMATION } from './app.animation';

export const ROOT_SELECTOR = 'app-root';

/**
 * App Component
 * Top Level Component
 */
@Component({
  selector: ROOT_SELECTOR,
  encapsulation: ViewEncapsulation.None,
  styleUrls: [
    './app.component.scss',
    './app.theme.scss'
  ],
  templateUrl: './app.component.html',
  animations: [APP_ANIMATION]
})
export class AppComponent implements OnDestroy, OnInit {

  icon = 'assets/images/icon.png';
  logo = 'assets/images/logo.png';
  text = 'ТОВ «Енергодосвід»';

  mediaQueryList: MediaQueryList;

  private readonly mobileQueryListener: () => void;

  constructor(private changeDetectorRef: ChangeDetectorRef,
              private mediaMatcher: MediaMatcher) {
    this.mediaQueryList = mediaMatcher.matchMedia('(max-width: 959px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mediaQueryList.addListener(this.mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mediaQueryList.removeListener(this.mobileQueryListener);
  }

  ngOnInit() {
  }
}
