<div fxLayout="column" fxFlex="10%"></div>
<div fxLayout="row" fxFlex="80%" fxLayoutAlign="space-between center" fxLayout.lt-sm="column">
  <div fxFlex="40%">
    <a mat-flat-button class="copyright" routerLink="">&copy;&nbsp;«Енергодосвід»</a>
  </div>
  <img alt="«Енергодосвід»" src="assets/images/icon.png" height="50%" fxHide.xs>
  <div fxFlex="40%">
    <iframe [src]="safeResourceUrl" #iframe [height]="height" [width]="width"></iframe>
  </div>
</div>
<div fxLayout="column" fxFlex="10%"></div>
