<section fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <div fxLayout="column" fxLayoutAlign="center center">
        <h2>Розцінки</h2>
        <h3>
          Розцінки на електромонтажні роботи Ви можете уточнити по телефону або будь-яким іншим зручним для Вас
          способом.
        </h3>
        <h3 class="hidden">
          Расценки на электромонтажные работы Вы можете уточнить по телефону или любым другим удобным для Вас способом.
        </h3>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex>
        <div fxLayout="row"></div>
        <iframe [src]="safeResourceUrl" height="100%" width="100%"></iframe>
        <div fxLayout="row"></div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <app-footer fxLayout="row" class="mat-toolbar-multiple-rows"></app-footer>
</section>
