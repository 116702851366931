import { Component, OnInit } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.css']
})
export class FooterComponent implements OnInit {

  safeResourceUrl: SafeResourceUrl;

  height: string;
  width: string;

  constructor(protected domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.safeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('fb.html');

    let userAgent = window.navigator.userAgent;

    if (/MSIE|Trident/.test(userAgent)) {
      this.height = '48px';
      this.width = '200px';
    } else {
      this.height = '44px';
      this.width = 'auto';
    }
  }

}
