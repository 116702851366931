import { Component, OnInit } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

import { FormBuilder, FormGroup, FormGroupDirective, Validators } from '@angular/forms';

import { MatSnackBar } from '@angular/material/snack-bar';

import { HttpClient } from '@angular/common/http';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-contact-us',
  templateUrl: './contact-us.component.html',
  styleUrls: ['./contact-us.component.scss']
})
export class ContactUsComponent implements OnInit {

  contactForm: FormGroup;

  process: boolean;

  safeResourceUrl: SafeResourceUrl;

  constructor(protected domSanitizer: DomSanitizer,
              private formBuilder: FormBuilder,
              private snackBar: MatSnackBar,
              private httpClient: HttpClient) {

    this.contactForm = this.formBuilder.group({
      name: [null, Validators.required],
      phone: [null, Validators.required],
      message: [null, Validators.required]
    });
  }

  ngOnInit() {
    this.safeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl('map.html');
    this.process = false;
  }

  onSend(form: FormGroupDirective) {
    this.process = true;
    this.httpClient.post('https://mail.hmc-dn.ru/sender2', form.control.value, {responseType: 'text'})
      .pipe(untilDestroyed(this))
      .subscribe(
        (value) => {
          this.snackBar.open(value, 'Успішно');
          form.resetForm({
            name: null,
            phone: null,
            message: null
          });
        },
        (error) => {
          this.snackBar.open(error.message, 'Помилка');
        }, () => {
          this.process = false;
        });
  }

  onEnter($event) {
    $event.preventDefault();
  }
}
