import { Injectable } from '@angular/core';

import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export abstract class StoreService<T> {

  protected bs: BehaviorSubject<T>;
  state$: Observable<T>;
  state: T;

  protected abstract store: string;

  protected constructor(initialValue: Partial<T>) {
    this.bs = new BehaviorSubject<T>(initialValue as T);
    this.state$ = this.bs.asObservable();

    this.state = initialValue as T;
    this.state$.subscribe((value: T) => {
      this.state = value;
    });
  }

  patch(newValue: Partial<T>, event: string = `Not specified`) {
    const newState = Object.assign({}, this.state, newValue);
    this.bs.next(newState);
  }
}
