<section fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <div fxLayout="row" fxLayoutAlign="center center">
        <h2>Контакти</h2>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex>
        <div fxLayout="row"></div>
        <div fxLayout="row"
             fxLayoutAlign="space-between stretch"
             fxLayout.lt-md="column"
             fxLayoutGap="4%"
             fxLayoutGap.lt-md="0">
          <div fxLayout="column" fxLayoutAlign="start start" fxFlex="50%" class="contact-us">
            <div fxLayout="row" fxLayoutAlign="start center">
              <img src="assets/images/phone.png" alt="Телефон">
              <a mat-flat-button href="tel:+380 67 461 16 31">+380 67 461 16 31</a>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <img src="assets/images/phone.png" alt="Телефон">
              <a mat-flat-button href="tel:+380 67 360 58 50">+380 67 360 58 50</a>
            </div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <img src="assets/images/phone.png" alt="Телефон">
              <a mat-flat-button href="tel:+380 67 380 12 90">+380 67 380 12 90</a>
            </div>
            <div fxLayout="row" fxHide.lt-md>&nbsp;</div>
            <div fxLayout="row">&nbsp;</div>
            <div fxLayout="row" fxLayoutAlign="start center">
              <img src="assets/images/email.png" alt="Еелектронна пошта">
              <a mat-flat-button href="mailto:office@energodosvid.com.ua">office@energodosvid.com.ua</a>
            </div>
            <div fxLayout="row" fxHide.lt-md>&nbsp;</div>
            <div fxLayout="row">&nbsp;</div>
            <div fxLayout="row" fxLayoutAlign="start start">
              <img src="assets/images/marker.png" alt="Адреса" class="marker">
              &nbsp;&nbsp;&nbsp;
              29013, Україна, м. Хмельницький, вул. Завадського, 54
            </div>
            <br>
          </div>
          <div fxLayout="column" fxFlex="50%">
            <iframe [src]="safeResourceUrl" height="300px" width="100%"></iframe>
          </div>
        </div>
        <div fxLayout="row">
          <div fxLayout="column" fxFlex="100%">
            <form [formGroup]="contactForm" #form="ngForm" (submit)="onSend(form)" novalidate>
              <div fxLayout="row"
                   fxLayoutAlign="space-between stretch"
                   fxLayout.lt-md="column"
                   fxLayoutGap="4%"
                   fxLayoutGap.lt-md="0">
                <div fxLayout="column" fxFlex="50%">
                  <mat-form-field>
                    <input matInput
                           (keydown.enter)="onEnter($event)"
                           placeholder="Ваше ім'я"
                           formControlName="name"
                           minlength="0"
                           maxlength="96">
                    <mat-error *ngIf="contactForm.controls.name.invalid">
                      Будь ласка, введіть ім'я.
                    </mat-error>
                  </mat-form-field>
                  <mat-form-field>
                    <input matInput
                           (keydown.enter)="onEnter($event)"
                           placeholder="Ваш телефон"
                           formControlName="phone"
                           minlength="0"
                           maxlength="96">
                    <mat-error *ngIf="contactForm.controls.phone.invalid">
                      Будь ласка, введіть номер телефону.
                    </mat-error>
                  </mat-form-field>
                </div>
                <div fxLayout="column" fxFlex="50%">
                  <mat-form-field>
                    <textarea matInput
                              placeholder="Ваше повідомлення"
                              formControlName="message"
                              minlength="0"
                              maxlength="2400"
                              matTextareaAutosize="false">
                    </textarea>
                    <mat-error *ngIf="contactForm.controls.message.invalid">
                      Будь ласка, введіть повідомлення.
                    </mat-error>
                  </mat-form-field>
                </div>
              </div>
              <br>
              <div fxLayout="row" fxLayoutAlign="center center">
                <div class="app-progress-spinner"></div>
                <button mat-raised-button [disabled]="contactForm.invalid || process">
                  надіслати повідомлення
                </button>
                <div class="app-progress-spinner">
                  <mat-spinner *ngIf="process" color="accent" diameter="36"></mat-spinner>
                </div>
              </div>
            </form>
          </div>
        </div>
        <div fxLayout="row"></div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <app-footer fxLayout="row" class="mat-toolbar-multiple-rows"></app-footer>
</section>
