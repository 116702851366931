<section fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <div fxLayout="column" fxLayoutAlign="center center">
        <h2>Калькулятор сонячної електростанції</h2>
        <h3>
          Розрахунок генерації електроенергії для сонячної електростанції в м. Хмельницький.
        </h3>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex>
        <div fxLayout="row"
             fxLayoutAlign="space-between stretch"
             fxLayout.lt-md="column"
             fxLayoutGap="4%"
             fxLayoutGap.lt-md="0">
          <div fxLayout="column" fxFlex="50%">
            <form [fxFlex]="mediaQueryList.matches ? '100%' : '30%'" [formGroup]="form0" novalidate>
              <mat-form-field class="wide">
                <input matInput
                       placeholder="Потужність панелей, кВт"
                       formControlName="power"
                       type="number"
                       step="0.1"
                       min="0"
                       (input)="onInput0($event)"
                       (keypress)="keyPress($event)">
                <mat-error *ngIf="form0.controls.power.invalid">
                  Будь ласка, введіть потужність панелей.
                </mat-error>
              </mat-form-field>
            </form>
          </div>
          <div fxLayout="column" fxFlex="50%">
            <form [fxFlex]="mediaQueryList.matches ? '100%' : '30%'" [formGroup]="form1" novalidate>
              <mat-form-field class="wide">
                <input matInput
                       placeholder="Річне споживання енергії, кВт*г"
                       formControlName="power"
                       type="number"
                       step="0.1"
                       min="0"
                       (input)="onInput1($event)"
                       (keypress)="keyPress($event)">
                <mat-error *ngIf="form1.controls.power.invalid">
                  Будь ласка, введіть річне споживання енергії.
                </mat-error>
              </mat-form-field>
            </form>
          </div>
        </div>
        <div fxLayout="row"
             fxLayoutAlign="space-between stretch"
             fxLayout.lt-md="column"
             fxLayoutGap="10%"
             fxLayoutGap.lt-md="0">
          <div fxLayout="column" fxFlex="50%">
            <canvas #chart0></canvas>
          </div>
          <div fxLayout="column" fxFlex="50%">
            <canvas #chart1></canvas>
          </div>
        </div>
        <div fxLayout="row" class="wide"></div>
        <div fxLayout="row" class="wide">
          <strong *ngIf="!hide0">Щорічне виробництво електричної енергії: <span class="copyright">{{annually}} кВт*г</span></strong>
        </div>
        <div fxLayout="row" class="wide">
          <strong *ngIf="!hide0 || !hide1">Незалежність від енергомережі: <span class="copyright">{{independence}} %</span></strong>
        </div>
        <div fxLayout="row">
          <span class="hint">
            Розрахунок генерації електроенергії є орієнтовним.
            Прогноз генерації, економічно вигідний і оптимальний підбір обладнання та комплектуючих Ви можете уточнити зв'язавшись з менеджером компанії.
          </span>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <app-footer fxLayout="row" class="mat-toolbar-multiple-rows"></app-footer>
</section>
