import { Injectable } from '@angular/core';

import { MatDialog } from '@angular/material/dialog';

import { WidgetDialogComponent } from '../components/widget-dialog/widget-dialog.component';

@Injectable({
  providedIn: 'root'
})
export class DialogService {

  constructor(public dialog: MatDialog) { }

  openWidgetDialog(tag: string, publicIds: string[], index: number): void {
    this.dialog.open(WidgetDialogComponent, { autoFocus: false, height: '80vh', width: '80vw', data: { tag, publicIds, index } });
  }
}
