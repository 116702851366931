import { Component, ChangeDetectionStrategy, OnInit } from '@angular/core';

import { MatSnackBar } from '@angular/material/snack-bar';

import { Service } from '../../interfaces/service';

import { DialogService } from '../../services/dialog.service';
import { ServiceService } from '../../services/service.service';

import { Observable } from 'rxjs';

@Component({
  selector: 'app-services',
  templateUrl: './services.component.html',
  styleUrls: ['./services.component.css'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ServicesComponent implements OnInit {

  services$: Observable<Service[]> | undefined;

  constructor(private snackBar: MatSnackBar,
              private dialogService: DialogService,
              private serviceService: ServiceService) { }

  ngOnInit() {
    this.services$ = this.serviceService.services$;
  }

  onShowGallery(tag: string, publicIds: string[], index: number) {
    this.dialogService.openWidgetDialog(tag, publicIds, index);
  }

}
