<section fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="column" fxLayoutAlign="center center">
    <h2>Сторінка яку ви шукаєте, не існує.</h2>
    <p>Можливо, ви помилилися в адресі або сторінка була переміщена.</p>
    <p>Будь ласка, перейдіть за <a routerLink="">цим</a> посиланням.</p>
    <img src="casco.png" alt="404">
  </div>
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
</section>
