import {
  animate,
  query,
  transition,
  trigger,
  style
} from '@angular/animations';

export const APP_ANIMATION =
  trigger('routeAnimation', [
    transition('* => *', [
      query(
        ':self',
        [style({opacity: 0}), animate('1s', style({opacity: 1}))],
        {optional: true}
      )
    ])
  ]);
