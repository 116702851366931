import { ChangeDetectorRef, Component, ViewChild, ElementRef, OnDestroy, OnInit } from '@angular/core';

import { MediaMatcher } from '@angular/cdk/layout';

import { FormBuilder, FormGroup, Validators } from '@angular/forms';

import { ScriptService } from '../../services/script.service';

declare let Chart: any;

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Component({
  selector: 'app-calculator',
  templateUrl: './calculator.component.html',
  styleUrls: ['./calculator.component.css']
})
export class CalculatorComponent implements OnDestroy, OnInit {

  @ViewChild('chart0', { static: true }) elementRef0: ElementRef;
  @ViewChild('chart1', { static: true }) elementRef1: ElementRef;

  form0: FormGroup;
  form1: FormGroup;

  chart0: any;
  chart1: any;

  annually: string;
  independence: string;

  hide0 = true;
  hide1 = true;

  mediaQueryList: MediaQueryList;

  private readonly mobileQueryListener: () => void;

  constructor(private formBuilder: FormBuilder,
              private scriptService: ScriptService,
              private changeDetectorRef: ChangeDetectorRef,
              private mediaMatcher: MediaMatcher) {
    this.mediaQueryList = mediaMatcher.matchMedia('(max-width: 959px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mediaQueryList.addListener(this.mobileQueryListener);
    // PV installed [kWp]
    this.form0 = formBuilder.group({
      power: [null, Validators.required]
    });
    // Energy consumption [kWh]
    this.form1 = formBuilder.group({
      power: [null, Validators.required]
    });

    this.form0.get('power').valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.hide0 = value === 0 || value === null;
      });

    this.form1.get('power').valueChanges
      .pipe(untilDestroyed(this))
      .subscribe((value) => {
        this.hide1 = value === 0 || value === null;
      });
  }

  ngOnDestroy(): void {
    this.mediaQueryList.removeListener(this.mobileQueryListener);
  }

  ngOnInit(): void {
    this.scriptService.load('cjs').then(() => {
      let context0 = this.elementRef0.nativeElement.getContext('2d');
      this.chart0 = new Chart(context0, {
        type: 'bar',
        data: {
          labels: [
            'Січень',
            'Лютий',
            'Березень',
            'Квітень',
            'Травень',
            'Червень',
            'Липень',
            'Серпень',
            'Вересень',
            'Жовтень',
            'Листопад',
            'Грудень'],
          datasets: [{
            label: 'потужність, кВт*г',
            backgroundColor: '#ffca28',
            data: []
          }]
        },
        options: {
          events: ['mousemove'],
          title: {
            display: true,
            text: 'Щомісячна енергія, отримана від системи з фіксованим кутом'
          },
          legend: {
            labels: {
              defaultFontFamily: "'Open Sans', sans-serif"
            }
          },
          tooltips: {
            backgroundColor: '#a88cda'
          }
        }
      });
      let context1 = this.elementRef1.nativeElement.getContext('2d');
      this.chart1 = new Chart(context1, {
        type: 'doughnut',
        data: {
          labels: [
            'споживання енергії, кВт*г',
            'виробництво енергії, кВт*г'],
          datasets: [{
            data: []
          }]
        },
        options: {
          events: ['mousemove'],
          title: {
            display: true,
            text: 'Баланс електричної енергії'
          },
          legend: {
            labels: {
              defaultFontFamily: "'Open Sans', sans-serif"
            }
          },
          tooltips: {
            backgroundColor: '#a88cda'
          },
          circumference: Math.PI,
          rotation: -Math.PI
        }
      });
    });
  }

  keyPress($event: KeyboardEvent) {
    let charCode = ($event.which) ? $event.which : $event.keyCode;
    if (charCode != 46 && charCode > 31
      && (charCode < 48 || charCode > 57)) {
      $event.preventDefault();
      return false;
    }
    return true;
  }

  onInput0($event: any) {
    // https://re.jrc.ec.europa.eu/pvg_tools/en/#PVP
    // Solar radiation database: PVGIS-SARAH
    // PV technology: Crystalline silicon
    // Installed peak PV power: 1kWp
    // System loss: 14%
    // Latitude:  49.42336°
    // Longitude: 26.96848°
    // Elevation: 277m
    const irradiation = [
      34.5,
      50.25,
      92.61,
      140.55,
      172.46,
      176.12,
      182.6,
      172.06,
      139.59,
      94.7,
      42.81,
      33.32]; // Monthly in-plane irradiation for fixed angle [kWh/m2]
    // Mounting position: Building integrated
    // Optimized slope and azimuth:
    // Slope angle: 35°
    // Azimuth angle: 0°
    const loss = 22.76; // Total loss [%]

    // PV energy production [kWh]
    let array = irradiation.map((power) => ($event.target.value * power * ((100 - loss) / 100)).toFixed(2));
    let production = array.map(parseFloat);

    // Yearly PV energy production [kWh]
    const reducer = (accumulator, currentValue) => accumulator + currentValue;
    this.annually = production.reduce(reducer).toFixed(2);

    this.chart0.data.datasets[0].data = production;
    this.chart0.update();

    let consumption = this.form1.value.power ? this.form1.value.power : 0;

    if (this.form1.value.power === null) {
      this.independence = '100';
    } else {
      this.independence = consumption !== 0 ? ((parseFloat(this.annually) / consumption) * 100).toFixed() : '0';
    }

    this.draw(consumption, this.annually);
  }

  onInput1($event: any) {
    let consumption = $event.target.value === '' ? '0' : $event.target.value;

    if ($event.target.value === '') {
      this.independence = '100';
    } else {
      this.independence = this.annually !== undefined ? ((parseFloat(this.annually) / parseFloat(consumption)) * 100).toFixed() : '0';
    }

    this.draw(consumption, this.annually);
  }

  private draw(value0: string, value1: string) {
    this.chart1.data.datasets[0] = {
      data: [value0, value1],
      backgroundColor: ['#ff7d7d', '#ffca28'],
      borderWidth: [0, 0]
    };
    this.chart1.update();
  }
}
