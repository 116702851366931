import { NgModule } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CommonModule } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule, PreloadAllModules } from '@angular/router';
import { ReactiveFormsModule } from '@angular/forms';
import { FlexLayoutModule } from '@angular/flex-layout';
/*
 * Platform and Environment providers/directives/pipes
 */
import { environment } from 'environments/environment';
import { ROUTES } from './app.routes';
// App is our top level component
import { AppComponent } from './app.component';
import { APP_RESOLVER_PROVIDERS } from './app.resolver';

// Application wide providers
const APP_PROVIDERS = [
  ...APP_RESOLVER_PROVIDERS
];

import { ENV_FIREBASE_CONFIG } from './firebase.config';

import { AngularFireModule } from '@angular/fire';
import { AngularFirestoreModule } from '@angular/fire/firestore';

import { ENV_CLOUDINARY_CONFIG } from './cloudinary.config';

import { CloudinaryModule, CloudinaryConfiguration } from '@cloudinary/angular-5.x';
import { Cloudinary } from 'cloudinary-core';

import { GalleryModule } from 'ng-gallery';

import { MaterialModule } from './modules/material.module';

import { NoContentComponent } from './components/no-content/no-content.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CompanyComponent } from './components/company/company.component';
import { DetailsComponent } from './components/details/details.component';
import { PricesComponent } from './components/prices/prices.component';
import { EquipmentComponent } from './components/equipment/equipment.component';
import { ServicesComponent } from './components/services/services.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';
import { FooterComponent } from './components/footer/footer.component';
import { WidgetDialogComponent } from './components/widget-dialog/widget-dialog.component';
import { CalculatorComponent } from './components/calculator/calculator.component';

/**
 * `AppModule` is the main entry point into Angular2's bootstraping process
 */
@NgModule({
  bootstrap: [ AppComponent ],
  declarations: [
    AppComponent,
    NoContentComponent,
    WelcomeComponent,
    CompanyComponent,
    DetailsComponent,
    PricesComponent,
    EquipmentComponent,
    ServicesComponent,
    ContactUsComponent,
    FooterComponent,
    WidgetDialogComponent,
    CalculatorComponent
  ],
  /**
   * Import Angular's modules.
   */
  imports: [
    BrowserAnimationsModule,
    BrowserModule,
    CommonModule,
    HttpClientModule,
    ReactiveFormsModule,
    FlexLayoutModule,
    AngularFireModule.initializeApp(ENV_FIREBASE_CONFIG),
    AngularFirestoreModule,
    CloudinaryModule.forRoot({Cloudinary}, ENV_CLOUDINARY_CONFIG as CloudinaryConfiguration),
    GalleryModule,
    MaterialModule,
    RouterModule.forRoot(ROUTES, {
      useHash: Boolean(history.pushState) === false,
      preloadingStrategy: PreloadAllModules
    })
  ],
  /**
   * Expose our Services and Providers into Angular's dependency injection.
   */
  providers: [
    environment.ENV_PROVIDERS,
    APP_PROVIDERS
  ]
})
export class AppModule {}
