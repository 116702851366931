import { Component, OnInit } from '@angular/core';

import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-equipment',
  templateUrl: './equipment.component.html',
  styleUrls: ['./equipment.component.css']
})
export class EquipmentComponent implements OnInit {

  publicIds: string[] = ['equipment/gaz-66', 'equipment/IMG_1063.wm'];

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  onShowGallery(tag: string, publicIds: string[], index: number) {
    this.dialogService.openWidgetDialog(tag, publicIds, index);
  }

}
