<div mat-dialog-title>
  <a mat-dialog-close
     mat-flat-button>
    <mat-icon>close</mat-icon>
  </a>
</div>
<div mat-dialog-content>
  <gallery *ngIf="galleryConfig$ | async; let config"
           [counter]="false"
           [thumbWidth]="config.thumbWidth"
           [thumbHeight]="config.thumbHeight"
           [thumbPosition]="config.thumbPosition"
           id="appGallery">
  </gallery>
</div>
