import { Injectable } from '@angular/core';

import { AngularFirestore, AngularFirestoreCollection } from '@angular/fire/firestore';

import { ServiceStoreService } from '../store/service-store.service';

import { Service } from '../interfaces/service';

import { Observable } from 'rxjs';

import { map, tap } from 'rxjs/operators';

import { UntilDestroy, untilDestroyed } from '@ngneat/until-destroy';

@UntilDestroy()

@Injectable({
  providedIn: 'root'
})
export class ServiceService {

  private collection: AngularFirestoreCollection<Service>;

  constructor(private afs: AngularFirestore,
              private store: ServiceStoreService) {

    this.collection = this.afs.collection<Service>('services', (ref) => ref.orderBy('createdAt', 'desc'));
    this.collection.snapshotChanges().pipe(
      map((actions) => actions.map((a) => {
        return {
          id: a.payload.doc.id,
          ...a.payload.doc.data()
        } as Service;
      })),
      tap((services: Service[]) => {
        this.store.patch({
          services
        }, `Services collection patched`);
      }),
      untilDestroyed(this)
    ).subscribe();
  }

  get services$(): Observable<Service[]> {
    return this.store.state$.pipe(map((state) => state.services.filter((service) => service.visible)));
  }
}
