<section fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <div fxLayout="column" fxLayoutAlign="center center">
        <h2>Наша техніка</h2>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex>
        <div fxLayout="row"></div>
        <div fxLayout="row"
             fxLayoutAlign="space-around stretch"
             fxLayout.lt-md="column"
             fxLayoutGap="10%"
             fxLayoutGap.lt-md="6%"
             class="gallery">
          <div fxLayout="column" fxLayoutAlign="space-evenly center">
            <cl-image public-id="{{ publicIds[0] }}"
                      (click)="onShowGallery(null, publicIds, 0)">
              <cl-transformation quality="90">
              </cl-transformation>
              <cl-transformation crop="scale" dpr="auto" width="420">
              </cl-transformation>
            </cl-image>
            <a mat-flat-button (click)="onShowGallery(null, publicIds, 0)">Ямобур (БКМ) на базі автомобіля ГАЗ-66</a>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-evenly center">
            <cl-image public-id="{{ publicIds[1] }}"
                      (click)="onShowGallery(null, publicIds, 1)">
              <cl-transformation quality="90">
              </cl-transformation>
              <cl-transformation crop="scale" dpr="auto" width="420">
              </cl-transformation>
            </cl-image>
            <a mat-flat-button (click)="onShowGallery(null, publicIds, 1)">Автовишка АП-17 на базі автомобіля ЗІЛ</a>
          </div>
        </div>
        <div fxLayout="row"></div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <app-footer fxLayout="row" class="mat-toolbar-multiple-rows"></app-footer>
</section>
