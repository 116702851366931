<section fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <div fxLayout="column" fxLayoutAlign="center center">
        <h2>Наші роботи</h2>
      </div>
      <div fxLayout="column" fxLayoutGap="2%" class="gallery">
        <div *ngFor="let service of services$ | async">
          <mat-card fxLayout="row"
                    fxLayout.lt-md="column"
                    fxLayoutGap="2%">
            <div fxLayout="column" fxLayoutAlign="start center">
              <cl-image public-id="{{ service.publicIds[0] }}"
                        (click)="onShowGallery(service.tags[0], service.publicIds, 0)"
                        crop="scale"
                        dpr="auto"
                        height="140">
                <cl-transformation quality="90">
                </cl-transformation>
              </cl-image>
            </div>
            <div fxLayout="column">
              <mat-card-header>
                <mat-card-title>{{ service.title }}</mat-card-title>
              </mat-card-header>
              <mat-card-content [innerHTML]="service.content"></mat-card-content>
            </div>
          </mat-card>
        </div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <app-footer fxLayout="row" class="mat-toolbar-multiple-rows"></app-footer>
</section>
