import { Routes } from '@angular/router';

import { NoContentComponent } from './components/no-content/no-content.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { CompanyComponent } from './components/company/company.component';
import { DetailsComponent } from './components/details/details.component';
import { PricesComponent } from './components/prices/prices.component';
import { EquipmentComponent } from './components/equipment/equipment.component';
import { ServicesComponent } from './components/services/services.component';
import { CalculatorComponent } from './components/calculator/calculator.component';
import { ContactUsComponent } from './components/contact-us/contact-us.component';

import { LandscapeImageResolver } from './resolvers/landscape-image-resolver.service';
import { PortraitImageResolver } from './resolvers/portrait-image-resolver.service';

export const ROUTES: Routes = [
  { path: 'company', component: CompanyComponent, data: { activation: 'company' } },
  { path: 'details', component: DetailsComponent, data: { activation: 'details' } },
  { path: 'prices', component: PricesComponent, data: { activation: 'prices' } },
  { path: 'equipment', component: EquipmentComponent, data: { activation: 'equipment' } },
  { path: 'services', component: ServicesComponent, data: { activation: 'services' } },
  { path: 'calculator', component: CalculatorComponent },
  { path: 'contact-us', component: ContactUsComponent, data: { activation: 'contact' } },
  {
    path: '',
    component: WelcomeComponent,
    resolve: { landscapeImage: LandscapeImageResolver, portraitImage: PortraitImageResolver },
    data: { activation: 'welcome' }
  },
  { path: '**', component: NoContentComponent }
];
