import { Component, OnInit } from '@angular/core';

import { DialogService } from '../../services/dialog.service';

@Component({
  selector: 'app-company',
  templateUrl: './company.component.html',
  styleUrls: ['./company.component.css']
})
export class CompanyComponent implements OnInit {

  publicIds: string[] = ['documents/0.License.wm', 'documents/1.Appendix.wm', 'documents/3.Certificate.wm'];

  constructor(private dialogService: DialogService) { }

  ngOnInit(): void {
  }

  onShowGallery(tag: string, publicIds: string[], index: number) {
    this.dialogService.openWidgetDialog(tag, publicIds, index);
  }

}
