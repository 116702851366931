import { Component, OnInit } from '@angular/core';

import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-prices',
  templateUrl: './prices.component.html',
  styleUrls: ['./prices.component.css']
})
export class PricesComponent implements OnInit {

  protected url = '/pdfjs-dist/web/viewer.html?file=/Розцінки.pdf';

  safeResourceUrl: SafeResourceUrl;

  constructor(protected domSanitizer: DomSanitizer) { }

  ngOnInit(): void {
    this.safeResourceUrl = this.domSanitizer.bypassSecurityTrustResourceUrl(this.url);
  }

}
