const devFirebaseConfig: any = {
  apiKey: "AIzaSyCF6eU1zukwrtKahOr13tq0ZDrrlD7b7Gw",
  authDomain: "dosvid-com-ua.firebaseapp.com",
  databaseURL: "https://dosvid-com-ua.firebaseio.com",
  projectId: "dosvid-com-ua",
  storageBucket: "dosvid-com-ua.appspot.com",
  messagingSenderId: "991489290877",
  appId: "1:991489290877:web:9279dd8b99ea66d0a77714"
};

const prodFirebaseConfig: any = {
  apiKey: "AIzaSyAIHk_R9Bn0rPu0CO5ELh5nYjCYT2qI4d0",
  authDomain: "energodosvid-com-ua.firebaseapp.com",
  databaseURL: "https://energodosvid-com-ua.firebaseio.com",
  projectId: "energodosvid-com-ua",
  storageBucket: "energodosvid-com-ua.appspot.com",
  messagingSenderId: "277623804380",
  appId: "1:277623804380:web:338d97ba2db59277e4bf19",
  measurementId: "G-GW7XG28VEF"
};

export const ENV_FIREBASE_CONFIG: any = process.env.NODE_ENV !== 'production' ?
  devFirebaseConfig :
  prodFirebaseConfig;
