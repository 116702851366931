import { Injectable } from '@angular/core';

import { StoreService } from './store.service';
import { ServiceState } from '../interfaces/service-state';

@Injectable({
  providedIn: 'root'
})
export class ServiceStoreService extends StoreService<ServiceState> {

  protected store: string;

  constructor() {
    super({
      services: []
    });
  }
}
