<section fxLayout="column" fxLayoutAlign="space-between stretch">
  <div fxLayout="row" class="mat-toolbar-multiple-rows"></div>
  <div fxLayout="row" fxLayoutAlign="space-between stretch" fxFlex>
    <div fxLayout="column" fxFlex="10%"></div>
    <div fxLayout="column" fxFlex="80%">
      <div fxLayout="column" fxLayoutAlign="center center">
        <h2>Компанія</h2>
        <h3>Товариство засноване досвідченими професіоналами в області електропостачання.</h3>
      </div>
      <div fxLayout="column">
        <div>Компанія має ліцензію на виконання електромонтажних робіт.</div>
        <div>Перебуваємо на загальній системі оподаткування, є платником ПДВ.</div>
      </div>
      <div fxLayout="column" fxLayoutAlign="space-between stretch" fxFlex>
        <div fxLayout="row"></div>
        <div fxLayout="row"
             fxLayoutAlign="space-between stretch"
             fxLayout.lt-md="column"
             fxLayoutGap="2%"
             fxLayoutGap.lt-md="6%"
             class="gallery">
          <div fxLayout="column" fxLayoutAlign="space-evenly center">
            <cl-image public-id="{{ publicIds[0] }}"
                      (click)="onShowGallery(null, publicIds, 0)">
              <cl-transformation quality="90">
              </cl-transformation>
              <cl-transformation crop="scale" dpr="auto" width="220">
              </cl-transformation>
            </cl-image>
            <a mat-flat-button (click)="onShowGallery(null, publicIds, 0)">Ліцензія</a>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-evenly center">
            <cl-image public-id="{{ publicIds[1] }}"
                      (click)="onShowGallery(null, publicIds, 1)">
              <cl-transformation quality="90">
              </cl-transformation>
              <cl-transformation crop="scale" dpr="auto" width="220">
              </cl-transformation>
            </cl-image>
            <a mat-flat-button (click)="onShowGallery(null, publicIds, 1)">Додаток до лицензії</a>
          </div>
          <div fxLayout="column" fxLayoutAlign="space-evenly center">
            <cl-image public-id="{{ publicIds[2] }}"
                      (click)="onShowGallery(null, publicIds, 2)">
              <cl-transformation quality="90">
              </cl-transformation>
              <cl-transformation crop="scale" dpr="auto" width="220">
              </cl-transformation>
            </cl-image>
            <a mat-flat-button (click)="onShowGallery(null, publicIds, 2)">Кваліфікаційний сертифікат</a>
          </div>
        </div>
        <div fxLayout="row"></div>
      </div>
    </div>
    <div fxLayout="column" fxFlex="10%"></div>
  </div>
  <app-footer fxLayout="row" class="mat-toolbar-multiple-rows"></app-footer>
</section>
