import { ChangeDetectorRef, Component, OnDestroy, OnInit } from '@angular/core';

import { MediaMatcher } from '@angular/cdk/layout';

import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnDestroy, OnInit {

  mediaQueryList: MediaQueryList;

  private readonly mobileQueryListener: () => void;

  constructor(public activatedRoute: ActivatedRoute,
              private changeDetectorRef: ChangeDetectorRef,
              private mediaMatcher: MediaMatcher) {
    this.mediaQueryList = mediaMatcher.matchMedia('(max-width: 768px)');
    this.mobileQueryListener = () => changeDetectorRef.detectChanges();
    this.mediaQueryList.addListener(this.mobileQueryListener);
  }

  ngOnDestroy(): void {
    this.mediaQueryList.removeListener(this.mobileQueryListener);
  }

  ngOnInit() {
  }

}
